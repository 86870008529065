@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100,200,300,400,500,600,700,800,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Monarda';
  src: url('/public/Monarda.ttf') format('truetype');
}

.monarda {
  font-family: 'Monarda', sans-serif; /* Use the custom font or fall back to a system font */
}

.modal{
  overflow-y: auto;
}

.option{
    background: linear-gradient(to right, #BE8AFF, #68C9FF);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .shadow-custom {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  }
  .swiper-button-prev {
    left: 20px;
    color: blue; 
  }
  
  .swiper-button-next {
    right: 20px;
    color: red;
  }
  .swiper-button-next::after{
    font-size: 25px;
   
    color: #060606;
}

.swiper-button-prev::after{
  
  font-size: 25px;
  color: #060606;
}

.swiper-button-next {
  position: absolute;
  right:-5px;
  
}

.swiper-button-prev {
  position: absolute;
  left:-5px;

}

/* .swiper-button-next, .swiper-button-prev {
  margin
} */

.custom-select {
  padding-right: 20px;
}

  .bg-height{
    height:calc(100vh - 112px);
  }

  .popup-overlay {
    background: rgba(24, 23, 23, 0.5); 
  }



  /* width */
/* ::-webkit-scrollbar {
  width: 12px;
  
} */
/* ::scrollbar-width: none; */


/* Track */
/* ::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */


